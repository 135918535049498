import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add", "container", "input", "remove"]
  static values = {
    dropzone: String
  }

  add(event) {
    event.preventDefault();
    this.addTarget.classList.add("hidden")
    this.removeTarget.classList.remove("hidden")

    const dropzone = document.getElementById(this.dropzoneValue)
    dropzone.append(this.containerTarget)

    this.hideDefaultText(dropzone)

    this.inputTarget.disabled = false
  }

  remove(event) {
    event.preventDefault();
    this.containerTarget.remove()
    this.showDefaultText()
  }

  hideDefaultText(dropzone) {
    const defaultText = dropzone.getElementsByClassName("default")[0]
    defaultText.className = "default hidden"
  }

  showDefaultText() {
    const dropzone = document.getElementById(this.dropzoneValue)
    const childCount = dropzone.getElementsByClassName("list-group-item").length
    if(childCount == 0) {
      const defaultText = dropzone.getElementsByClassName("default")[0]
      defaultText.className = "default"
    }
  }
}
