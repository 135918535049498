import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable", "hideButton", "showButton" ]

  initialize() {
    $(this.showButtonTarget).hide()
  }

  show(){
    $(this.toggleableTarget).show()
    $(this.showButtonTarget).hide()
    $(this.hideButtonTarget).show()
  }
  hide(){
    $(this.toggleableTarget).hide()
    $(this.hideButtonTarget).hide()
    $(this.showButtonTarget).show()
  }
}
