// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import '@stimulus/polyfills'
import { Application } from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
application.register('nested-form', NestedForm)
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
