import moment from 'moment'
import bsCustomFileInput from 'bs-custom-file-input'
//console.log("loading the annuvia.js file");

// function validateEmail(form_id, email) {
//     var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
//     var address = document.forms[form_id].elements[email].value;
//     if (reg.test(address) == false) {
//         alert('Missing or Invalid Email Address');
//         return false;
//     }
//     return true;
// }

window.isValidDate = function(dateString){
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  if(Number.isNaN(d.getTime())) return false; // Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

/* does not seem to be used*/
function set_selected_tab(tab_name) {
    //$$('#map-table a.active').invoke('removeClassName','active');
    //$(tab_name).addClassName('active');
    $('#map-table a.active').removeClass('active');
    $("#" + tab_name).addClass('active');
}

// expects # before the menu name
window.set_selected_map_view = function(menu_name) {
    // prototype based
    //$$('#map-list-view a.active').invoke('removeClassName','active');
    //$(menu_name).addClassName('active');

    // jquery
    $('#map-list-view a.active').removeClass('active');
    $(menu_name).addClass('active');

}

window.isDate = function(txtDate) {
    var objDate, // date object initialized from the txtDate string
        mSeconds, // txtDate in milliseconds
        day, // day
        month, // month
        year;     // year
    // date length should be 10 characters (no more no less)    
    if (txtDate.length !== 10) {
        return false;
    }
    // third and sixth character should be '/'    
    if (txtDate.substring(4, 5) !== '/' || txtDate.substring(7, 8) !== '/') {
        return false;
    }
    // extract month, day and year from the txtDate (expected format is yyyy/mm/dd)
    // subtraction will cast variables to integer implicitly (needed    
    // for !== comparing)    
    month = txtDate.substring(5, 7) - 1; // because months in JS start from 0    
    day = txtDate.substring(8, 11) - 0;
    year = txtDate.substring(0, 4) - 0;    // test year range    
    //alert("month:" + month + " year:" + year + " day:" + day);
    if (year < 1000 || year > 3000) {
        return false;
    }    // convert txtDate to milliseconds    
    mSeconds = (new Date(year, month, day)).getTime();    // initialize Date() object from calculated milliseconds    
    objDate = new Date();
    objDate.setTime(mSeconds);
    // compare input date and parts from Date() object    
    // // if difference exists then date isn't valid    
    if (objDate.getFullYear() !== year || objDate.getMonth() !== month || objDate.getDate() !== day) {
        return false;
    }
    // // otherwise return true   
    return true;
}

window.validateDate = function(elementId) {
    var textDate;

    textDate = $(elementId).val();
    //alert('in validatedate' + textDate);
    if (textDate.trim().length == 0)
        return true;
    // calls the function above
    if (!isDate(textDate)) {
        alert("Not a valid Date: " + textDate);
        $(elementId).addClass('errorExplanation');
        return false
    }
    else {
        $(elementId).removeClass('errorExplanation');
        return true;
    }
}

// usage: <INPUT id="txtChar" onkeypress="return isNumberKey(event)" type="text" name="txtChar">

window.isNumberKey = function(evt) {
    var charCode = (evt.which) ? evt.which : event.keyCode
    // original charCode != 46 &&
    // decimal dot = 46
    if (charCode == 46)
    return true;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
    {
        //alert("char code was " + charCode);
        return false;
    }

    return true;
}

window.showHelp = function() {


    //$('bg-center-div').addClassName('float_left');

    // hopefully jquery

    $("#bg-center-div").addClass('float_left');

}

window.hideHelp = function() {
    //document.getElementById("leftside").style.width="100%";
    $("#rightside").css('display',"none")
    narrowBody();

}

window.showOnCheck = function(element_id, input_element) {
    if ( $(input_element).is(":checked"))
        $(element_id).show();
        else
        $(element_id).hide();

}

window.narrowBody = function() {
    // $$('body')[0].removeClassName('wide_body');
    //  $$('body')[0].addClassName('narrow_body');

    // hopefully jquery
    $('#bg-center-div').removeClass('float_left');
}

window.SetAnswer = function(title, content) {
    document.getElementById("answers").style.display = "block";
    document.getElementById("heading").innerHTML = title;
    document.getElementById("answer").innerHTML = "<p>" + content + "</p>";
}

window.HideAnswer = function() {
    $("#answers").css('display',"none")
}

window.ChangeImage = function(image, id) {
    document.getElementById(id).src = "/assets/" + image;
}

window.GrayOut = function() {
    $("#GrayOver").css('display',"block")
}

window.RemoveGrayOut = function() {
    $("#GrayOver").css('display',"none")
}

window.DjCheckMaxlength = function(oInObj)
{
    var iMaxLen = parseInt(oInObj.getAttribute('maxlength'));
    var iCurLen = oInObj.value.length;

    if ( oInObj.getAttribute && iCurLen > iMaxLen )
    {
        oInObj.value = oInObj.value.substring(0, iMaxLen);
    }
} //@ END OF DjCheckMaxlength()

window.copyAddressFromShippingToBilling = function() {
    if ($('#shipping_address_line_one').val()) {
        $('#billing_address_line_one').val($('#shipping_address_line_one').val());
        $('#billing_address_line_two').val($('#shipping_address_line_two').val());
        $('#billing_address_city').val($('#shipping_address_city').val());
        $('#billing_address_state').val($('#shipping_address_state').val());
        $('#billing_address_zip_code').val($('#shipping_address_zip_code').val());
    }
    else
    alert("No data in shipping line one field to be copied");

}

// http://stackoverflow.com/questions/22581345/click-button-copy-to-clipboard-using-jquery
window.copyToClipboard = function(elementId) {

    // Create a "hidden" input
    var aux = document.createElement("input");

    // Assign it the value of the specified element
    aux.setAttribute("value", document.getElementById(elementId).innerHTML);

    // Append it to the body
    document.body.appendChild(aux);

    // Highlight its content
    aux.select();

    // Copy the highlighted text
    document.execCommand("copy");

    // Remove it from the body
    document.body.removeChild(aux);

}

// special function to deal with HTML-encoded chars on the hover on the map
window.decodeHtml = function (html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

$(function () {
    $('.ajax_table th').on('click', 'a', function () {
        $.getScript(this.href);
        return false;
    });
})

$(function () {
    $('#sortable_table_div th a, #sortable_table_div .pagination a').on('click', function () {
       // $("#spinner-div").show();
       // $("#sortable_table_div").hide();

        $.getScript(this.href);
        return false;
    });
});

// WATCH OUT: this function as part of the map page location tab does not get called as that partial gets reloaded
$(function () {
    $('#locations_tab th a, #locations_tab .pagination a').on('click', function () {
//alert('hitting in 224 annuvia.js');
        $.getScript(this.href);
        return false;
    });
});

window.scroll_to_anchor = function(anchor_id){
    var tag = $("#"+anchor_id);
    $('html,body').animate({scrollTop: tag.offset().top},'slow');
}

//function detect_caps_lock_on(elementId) {
window.detect_caps_lock_on = function (elementId) {
    // Get the input field
    var input = document.getElementById(elementId);

    // When the user presses any key on the keyboard, run the function
    input.addEventListener("keyup", function(event) {

        // If "caps lock" is pressed, display the warning text
        if (event.getModifierState("CapsLock")) {
            $('[data-toggle="tooltip"]').tooltip();
            $('#'+elementId).tooltip('show')
            //text.style.display = "block";
        } else {
            $('#'+elementId).tooltip('hide')
        }
    });

}

window.moment = moment;