import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "source", "destination" ]
  static values = {
    url: String,
  }

  select(event) {
    $.post(this.urlValue,
      { reseller_id: event.target.selectedOptions[0].value, target: this.destinationTarget.id }
    )
  }
}