import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["exclude"]
  static values = {
    exclude: String,
    formId: String
  }

  submit(event) {
    const form = document.getElementById(this.formIdValue)
    const formData = new FormData(form)
    const excludeIDs = formData.getAll(this.excludeValue)

    this.excludeTarget.value = excludeIDs
  }
}
