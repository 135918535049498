import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "unread" ]
  static targets = ["unread"]

  read() {
    if(this.element.classList.contains(this.unreadClass)) {
      this.element.classList.remove(this.unreadClass)

      this.unreadTargets.forEach(el => {
        el.classList.remove(this.unreadClass)
      })

      const counts = document.getElementsByClassName("unread_count")
      Array.from(counts).forEach(count => {
        count.textContent = count.textContent - 1
      })
    }
  }
}