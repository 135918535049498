import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.select = new TomSelect(`#${this.element.id}`,
      {
        plugins: ["input_autogrow", "remove_button"],
        hideplaceholder: true,
        maxOptions: 250,
      });
  }

  disconnect() {
    this.select.destroy()
  }
}