import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["theme"]

  show(e) {
    const selected_theme = `theme-${e.target.value || "default"}`
    this.themeTargets.forEach(theme => {
      if(theme.id == selected_theme) {
        theme.classList.remove("hidden")
      } else {
        theme.classList.add("hidden")
      }
    })
  }
}
