import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "body", "column", "row", "indicator" ]
  static values = {
    column: String,
    direction: Number
  }

  connect() { }

  // 1 = ascending order, -1 = descending order
  getSortDirection(column) {
    if (this.columnValue == column) {
      return (this.directionValue == 1 ? -1 : 1)
    } else {
      return 1
    }
  }

  getSortColumnOrderAndIndex(event) {
    const column = event.target.innerHTML.trim()
    const direction = this.getSortDirection(column)

    let index = -1
    this.columnTargets.forEach((currentColumn, idx) => {
      const columnText = currentColumn.querySelectorAll(".text")[0]
      if (columnText !== undefined && columnText.innerHTML.trim() == column) {
        index = idx
      }
    })
    return { index: index, column: column, direction: direction }
  }

  getComparableValue(row, index) {
    const td = row.querySelectorAll("td")[index]
    if (td.innerHTML.trim() == "") {
      return ""
    } else {
      return td.querySelectorAll("a")[0].innerHTML
    }
  }

  setDirectionIndicators(target) {
    this.indicatorTargets.forEach(target => {
      target.classList.remove("glyphicon", "glyphicon-chevron-up","glyphicon-chevron-down")
    })

    const newIndicator = `glyphicon-chevron-${this.directionValue == 1 ? "up" : "down"}`
    target.querySelector(".indicator").classList.add("glyphicon", newIndicator)
  }

  sort(event) {
    const { index, column, direction } = this.getSortColumnOrderAndIndex(event)
    if (index == -1) {
      return
    }

    this.columnValue = column
    this.directionValue = direction

    this.setDirectionIndicators(event.currentTarget);

    const newRows = Array.from(this.rowTargets)
    newRows.sort((rowA, rowB) => {
      const cellA = this.getComparableValue(rowA, index)
      const cellB = this.getComparableValue(rowB, index)

      switch(true) {
        case cellA > cellB:
          return 1 * direction
        case cellA < cellB:
          return -1 * direction
        case cellA === cellB:
          return 0;
      }
    })

    this.rowTargets.forEach(row => {
      this.bodyTarget.removeChild(row)
    })

    newRows.forEach(newRow => {
      this.bodyTarget.appendChild(newRow)
    })
  }

}