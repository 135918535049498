// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//

require("@rails/ujs").start()
global.Rails = Rails;
//require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")
//= require filterrific/filterrific-jquery

require("jquery"); // Don't really need to require this...
require("jquery-ui");
require('jquery-ui/ui/widgets/draggable.js');
require('jquery-ui/ui/widgets/resizable.js');
require('jquery-ui/ui/widgets/droppable.js');
require('jquery-ui/ui/widgets/accordion.js');
require('jquery-ui/ui/widgets/datepicker.js');
require('jquery-ui/ui/widgets/autocomplete.js');
require("moment");
require("iframe-resizer")
import $ from 'jquery'
window.jQuery = $
window.$ = $
import './gradient-progress-bar' // this is necessary.
import './jquery.validate'
import './jstree'
import './annuvia'
import './bootstrap-3'
import './bootstrap-multiselect'
import './check'
//import './jquery.fittext'
import './jquery.rateit'
import './jquery.typeahead'
import './jquery-maphilight'
// import './bootstrap-clockpicker' - causes js errors in console
import '../controllers'

import "controllers"

require("trix")
require("@rails/actiontext")