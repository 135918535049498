window.valbutton = function() {

    // require at least one radio button be selected for each question

    var any_problem = false;


    if (!$('#record_ready_check_true').is(':checked') && !$('#record_ready_check_false').is(':checked')) {
        alert("Please answer the first question.");
        return (false);
    }
    else if ($('#record_ready_check_false').is(':checked')) {
        any_problem = true;
    }

    // second question

    if (!$('#record_clean_check_true').is(':checked') && !$('#record_clean_check_false').is(':checked')) {
        alert("Please answer the second question.");
        return (false);
    }
    else if ($('#record_clean_check_false').is(':checked')) {
        any_problem = true;
    }

    // third question
    if (!$('#record_no_warnings_check_true').is(':checked') && !$('#record_no_warnings_check_false').is(':checked')) {
        alert("Please answer the third question.");
        return (false);
    }
    else if ($('#record_no_warnings_check_false').is(':checked')) {
        any_problem = true;
    }

    // fourth question
    if (!$('#record_pads_check_true').is(':checked') && !$('#record_pads_check_false').is(':checked')) {
        alert("Please answer the fourth question.");
        return (false);
    }
    else if ($('#record_pads_check_false').is(':checked')) {
        any_problem = true;
    }

    if ($('#record_name_checker').val().length < 2) {
        alert('Please enter your name (at least 2 characters).');
        return false;
    }

    if(document.getElementById("edit_placement_1")) {
        if (!$('#edit_placement_1').is(':checked') && !$('#edit_placement_0').is(':checked')) {
            alert('Please indicate if the placement is correct.');
            return false;
        }
    }

    if (any_problem) {
        var r = confirm("Are you sure a test failed? A support ticket will be generated and you will be contacted by Customer Service. Press OK to confirm that there is a problem with your AED.");
        return (r);
    }

    ;        // theForm.submit(); // this line submits the form after validation
}

window.show_notes_div = function(device_id) {
    //alert("in show notes test");
    // show notes div if one or more of answers is false
    var noSelected = false;

    if ($('#record_ready_check_false').is(':checked')) {
        noSelected = true;
    }

    if ($('#record_clean_check_false').is(':checked'))
        noSelected = true;

    if ($('#record_no_warnings_check_false').is(':checked'))
        noSelected = true;

    if ($('#record_pads_check_false').is(':checked'))
        noSelected = true;

    if (noSelected) {
        $('#notes_area_' + device_id).show();
    }
    else {
        $('#notes_area_' + device_id).hide();
    }
}

function commonYesToAll() {
  $(".true").prop("checked", true);
  $(".false").prop("checked", false);
  if(document.getElementById("notes_area")) {
      $('#notes_area').hide();
  }
}

window.yesToAllQuestions = function() {
    commonYesToAll();
    if(document.getElementById("edit_placement_1")) {
        $("#edit_placement_1").prop("checked", true);
    }
    if(document.getElementById("reveal-if-no")) {
        $('#reveal-if-no').removeClass('reveal')
    }
}

window.yesToAllQuestionsMobile = function() {
    commonYesToAll();
    if(document.getElementById("all_requirements_true")) {
        $("#all_requirements_true").prop("checked", true);
    }
}

window.valbuttonMobile = function() {
    var els = document.getElementsByClassName("checkbox-inline");
    var true_checks = 0, false_checks = 0, e = false;
    $(".true").each(function(i,obj) { if ($(this).is(":checked")) { true_checks += 1; } });
    $(".false").each(function(i,obj) { if ($(this).is(":checked")) { false_checks += 1; e = true; } });

    if (els.length != 2 * (true_checks + false_checks)) {
      alert("Please make sure you answer all questions.")
      return false;
    }

    if (e && $("#record_notes").val().length < 5)
        return alert("Please provide a more detailed description of the problem."), false;

    if ($("#record_name_checker").val().length < 2)
        return alert("Please enter your name (at least 2 characters)."), false;

    if (false_checks > 0) {
        var t = confirm("Are you sure a test failed? A support ticket will be generated and you will be contacted by Customer Service. Press OK to confirm that there is a problem.");

        if (t) {
            $("#theForm").submit();
        }
    }
    else {
        $("#theForm").submit();
    }
}

window.show_notes_divMobile = function() {
    var e = false;
    if ($("#record_ready_check_false").is(":checked"))
        e = true;

    if ($("#record_clean_check_false").is(":checked"))
        e = true;

    if ($("#record_no_warnings_check_false").is(":checked"))
        e = true;

    if ($("#record_pads_check_false").is(":checked"))
        e = true;

    e ? $("#notes_area").show() : $("#notes_area").hide()
}

$(function () {
    // -- START: This is for the UI date picker and to initialize it with the hardcoded dates. I would use the datepicker and settings that the current ARCH app uses. -->
    // $(".calendar_input").datepicker();
    //$('#installed-battery').datepicker("setDate", '02/01/2018');
    //$('#secondary-battery').datepicker("setDate", '04/01/2022');
    //$('#installed-pads').datepicker("setDate", '05/01/2019');
    //$('#secondary-pads').datepicker("setDate", '12/01/2017');
    // -- END: Date picker init -->
    // -- START: This is the "NO" button where the user clicks to edit the content. It shows all the content with the class "edit" and hides all the content that has the class "save" -->
    $(".edit-button").click(function () {
        $('.save').css('display', 'none');
        $('.edit').css('display', 'block');
    });
    // -- END -->

    // -- START: Save button after you edit the content. It shows content with the class "save" and hides all content with class "hide"-->
    $(".save-button").click(function () {
        $('.save').css('display', '');
        $('.edit').css('display', 'none');
    });
    // -- END -->

    // -- Start: cancel button that shows if you are editing the expiration dates -->
    $("#cancel_edit_button").click(function () {
        $('.save').css('display', 'block');
        $('.edit').css('display', 'none');
    });
    // -- END -->


    //-- START: "Yes" button in the Info section. Once clicked it opens up then check section. -->
    $(".step-1").click(function () {
        //alert('removing step 1')
        $('#item-info a').click();
        $('#panel-info').removeClass('disabled');
        $('#collapse-panel-info').collapse("show");
        //Checks to see if Check section is collapsed. If it is to open it up.
        if ($('#panel-info a').hasClass('collapsed')) {
            //alert('going to click again');
            $('#panel-info a').click();
        }

    });
    // -- END -->

    // -- START: Check all requirements "YES" answer -->
    $("#all_requirements_true").click(function () {
        //$('input:radio[value=true]').prop('checked', true);
        $("#all_requirements_true").prop("checked", true);
        yesToAllQuestionsMobile();

        $('.answers').css('display', 'none');

        //alert("set all questions to checked: " );
        $('.form-group:last-child, .btn-success').removeClass('disabled');
    });
    // -- END -->

    // -- START: Check all requirements "NO" answer -->
    $("#all_requirements_false").click(function () {
        // alert("if false hit");
        $('input:radio[value=true]').not(this).removeAttr('checked');
        $('.btn-success').addClass('disabled');
        $('.require').hide();
        $('.answers').css('display', 'table-cell');
        $('#notes_area').show();
    });
    // -- END -->

    // -- START: Check "Submit" button -->
    $(".submit-button").click(function () {
        $('#item-check-info').removeClass('disabled');
        $('#panel-info a').click();
        //Checks to see if Past Checks section is collapsed. If it is to open it up.
        if ($('#item-check-info a').hasClass('collapsed')) {
            $('#item-check-info a').click();
        }
    });
    // -- END -->

    // -- START: This was a way to quickly fake the check validation. I wouldn't use this as a true means to validate the form. Remove it from the live version. -->
    $('#theForm > :nth-child(4) input').click(function () {
        $('.btn-success').removeClass('disabled');
    });
    // -- END -->

});