import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["exclude", "roles", "organizations"]
  static values = {
    exclude: String,
    roles: String,
    organizations: String,
    formId: String,
  }

  submit(event) {
    const form = document.getElementById(this.formIdValue)
    const formData = new FormData(form)
    const excludeIDs = formData.getAll(this.excludeValue)
    const roles = formData.getAll(this.rolesValue)
    const organizationIDs = formData.getAll(this.organizationsValue)

    console.log(`EXCLUDE ==> ${excludeIDs}`)

    this.excludeTarget.value = excludeIDs
    this.rolesTarget.value = roles
    this.organizationsTarget.value = organizationIDs
  }
}